import React, { FC, useContext, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';

import * as TrustpilotService from './TrustpilotService';
import {
  STARS,
  TEMPLATES,
  DEFAULT_LOCALE_BUSINESS_ID,
  URL_TRUSTPILOT_SCRIPT,
  DEFAULT_LOCALE,
  REVIEW_LANGUAGES,
  AVAILABLE_LANGUAGES,
} from './constants';

import dataSelector from '~/shared/util/data-selector';
import useScript from '~/shared/hooks/use-script';
import useIsMobile from '~/shared/hooks/use-is-mobile';
import SiteContext from '~/shared/providers/SiteContext';

const wrapperStyles = ({
  theme,
  height,
}: {
  theme?: Theme;
  height: string;
}) => css`
  width: 100%;
  height: ${height};
  padding: 0;
  ${theme.mq.kilo} {
    padding: 0 ${theme.spacings.tera};
  }
`;

const Wrapper = styled('div')(wrapperStyles);

export interface TrustpilotProps {
  template: string;
  stars: string;
  reviewLanguages: string;
  filterByTag: string;
}

/**
 * Component for displaying different Trustpilot widgets.
 */
const Trustpilot: FC<TrustpilotProps> = ({
  template = TEMPLATES.CAROUSEL,
  stars = STARS,
  reviewLanguages = REVIEW_LANGUAGES.SAME_AS_PAGE,
  filterByTag,
}) => {
  const trustPilotRef = useRef();
  const templateConfig = TrustpilotService.getTemplateConfig(template);
  const isMobile = useIsMobile();
  const widgetHeight = templateConfig[isMobile ? 'mobileHeight' : 'height'];

  const { locale = DEFAULT_LOCALE }: { locale: string } =
    useContext(SiteContext);

  const pageLanguage = locale.slice(0, 2);
  const languages =
    reviewLanguages === REVIEW_LANGUAGES.SAME_AS_PAGE
      ? [pageLanguage]
      : AVAILABLE_LANGUAGES;

  useScript(URL_TRUSTPILOT_SCRIPT, false);
  useEffect(() => {
    if (window.Trustpilot) {
      TrustpilotService.updateExistingTrustpilotInstance(trustPilotRef);
    }
  }, [trustPilotRef, widgetHeight]);

  if (!templateConfig && DEFAULT_LOCALE_BUSINESS_ID) {
    return null;
  }

  return (
    <Wrapper
      height={widgetHeight}
      data-selector={dataSelector('section', 'trustpilot')}
      data-elbcontext="component:trustpilot"
    >
      <div
        ref={trustPilotRef}
        data-locale={locale}
        data-template-id={templateConfig.id}
        data-businessunit-id={DEFAULT_LOCALE_BUSINESS_ID}
        data-review-languages={languages}
        data-style-height={widgetHeight}
        data-style-width={templateConfig.width}
        data-theme={templateConfig.theme}
        data-stars={stars || STARS}
        data-tags={filterByTag}
        className="trustpilot-widget"
      />
    </Wrapper>
  );
};

export default Trustpilot;
