// Adapted from https://usehooks.com/useScript/
import { useState, useEffect } from 'react';
import includes from 'lodash/fp/includes';

import isServer from '~/shared/util/is-server';

const cachedScripts = [];

export default function useScript(src, async = true) {
  // Keeping track of script loaded and error state

  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(() => {
    if (isServer) {
      return undefined;
    }

    // If cachedScripts array already includes src that means another instance
    // of this hook already loaded this script, so no need to load again.
    if (includes(src, cachedScripts)) {
      setState({
        loaded: true,
        error: false,
      });
      return undefined;
    }

    cachedScripts.push(src);

    // Create script
    const script = document.createElement('script');

    script.src = src;
    script.async = async;

    // Script event listener callbacks for load and error
    const onScriptLoad = () => {
      setState({
        loaded: true,
        error: false,
      });
    };

    const onScriptError = () => {
      // Remove from cachedScripts so we can try loading again
      const index = cachedScripts.indexOf(src);

      if (index >= 0) {
        cachedScripts.splice(index, 1);
      }

      script.remove();

      setState({
        loaded: true,
        error: true,
      });
    };

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    // Add script to document body
    document.head.appendChild(script);

    // Remove event listeners on cleanup
    return () => {
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
    };
  }, [src, async]);

  return [state.loaded, state.error];
}
