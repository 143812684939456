import { MutableRefObject } from 'react';

import { TEMPLATE_CONFIGS } from './constants';

type Trustpilot = {
  loadFromElement: (e: MutableRefObject<undefined>, t: boolean) => void;
};

export const updateExistingTrustpilotInstance = (
  ref: MutableRefObject<undefined>,
) => {
  // Trustpilot will throw exception here if process.env.NEXT_PUBLIC_TRUSTPILOT_ID is not specified
  try {
    (window.Trustpilot as Trustpilot).loadFromElement(ref.current, true);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export const getTemplateConfig = (templateKey: string) =>
  TEMPLATE_CONFIGS[templateKey];
